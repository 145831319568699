import Chat, { Bubble, useMessages, Empty } from "@chatui/core";
import { Button } from "antd";
import { Image } from "antd";

import "@chatui/core/dist/index.css";
import "./chatui-theme.css";
import "../../App.css";
import { SSE } from "sse.js";
import React, { useEffect, forwardRef, useImperativeHandle } from "react";
import { SendMsg } from "../../Service/SSE";
import { UploadEvent } from "../../Api/UploadEvent";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import { CopyOutlined, SendOutlined } from "@ant-design/icons";
import { message } from "antd";

const generateUUID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};
const quickOperstionList = [
  {
    question: "明天早上八点到九点要开会过一下本月的进度计划",
    icon: require("../../Assets/chat/img2.png"),
    title: "日程安排",
  },
  {
    question: "明天九点前我要把进度计划准备好,记个todo吧",
    icon: require("../../Assets/chat/img3.png"),
    title: "记待办",
  },
  {
    question: "小卡助理网页版的PM是葛云飞 geyunfei@kattgatt.com 保存一下",
    icon: require("../../Assets/chat/img4.png"),
    title: "添加联系人",
  },
  {
    question: "给我拟一个销售部的季度总结,500字左右,保存下来",
    icon: require("../../Assets/chat/img6.png"),
    title: "写文档",
  },
  {
    question:
      "小卡助理网页版的PM是葛云飞 geyunfei@kattgatt.com，告诉他我很喜欢这个产品，但是还有一些不足和改进的地方，要多努力",
    icon: require("../../Assets/chat/img1.png"),
    title: "发邮件",
  },
  {
    question: "用python实现一个排序算法，并详细的给我做一下解释",
    icon: require("../../Assets/chat/img5.png"),
    title: "学编程",
  },
  {
    question: "我要怎么向老板用英语表达，销售计划可以达成，但是风险和挑战很大",
    icon: require("../../Assets/chat/img7.png"),
    title: "提外语",
  },
];

const MarkdownBubble = (props) => {
  const onCopy = (content) => {
    console.log(content);
    navigator.clipboard.writeText(content).then((res) => {
      message.info("复制成功");
    });
  };
  return (
    <Bubble>
      <ReactMarkdown
        children={props.content}
        components={{
          code({ node, inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || "");
            return !inline && match ? (
              <div
                className="markdown-code_block"
                style={{
                  position: "relative",
                }}
              >
                <Button
                  className="copy_btn"
                  type="dashed"
                  ghost
                  size="small"
                  icon={<CopyOutlined></CopyOutlined>}
                  onClick={() => onCopy(children)}
                ></Button>
                <SyntaxHighlighter
                  {...props}
                  children={String(children).replace(/\n$/, "")}
                  style={vscDarkPlus}
                  language={match[1]}
                  showLineNumbers={true}
                  wrapLines={false}
                  onCopy={() => {
                    onCopy(children);
                  }}
                  renderInline={true}
                ></SyntaxHighlighter>
              </div>
            ) : (
              <code {...props} className={className}>
                {children}
              </code>
            );
          },
          img(props) {
            return <Image {...props} width={320}></Image>;
          },
        }}
      ></ReactMarkdown>
    </Bubble>
  );
};

const ChatComponents = forwardRef(({ onTitleUpdate, className }, ref) => {
  const { messages, appendMsg, setTyping, updateMsg, resetList } = useMessages(
    []
  );
  const [chatId, setChatId] = React.useState("");
  function handleSend(type, val) {
    if (type === "text" && val.trim()) {
      appendMsg({
        _id: generateUUID(),
        type: "content",
        content: { type: "content", text: val },
        position: "right",
      });
      UploadEvent("ChatInput", {
        text_length: val.length,
        page: "chat",
      });
      setTyping(true);
      let lastMsg = null;
      SendMsg(chatId, val, (response) => {
        try {
          setChatId(response.chat_id);
          let id = generateUUID();
          if (lastMsg !== null && lastMsg.content.type === "calling") {
            lastMsg.content.isProcessed = true;
            updateMsg(lastMsg._id, lastMsg);
          }
          if (response.type === "calling") {
            lastMsg = {
              content: {
                function_name: response.function_name,
                type: response.type,
                isProcessed: false,
              },
              _id: id,
            };
            appendMsg(lastMsg);
            UploadEvent("CallingRunning", {
              page: "chat",
              function_name: response.function_name,
            });
          } else if (response.type === "content") {
            if (lastMsg !== null && lastMsg.content.type === "content") {
              lastMsg.content.text = lastMsg.content.text + response.text;
              updateMsg(lastMsg._id, lastMsg);
            } else {
              lastMsg = {
                _id: id,
                type: response.type,
                content: {
                  type: response.type,
                  text: response.text.replace(/<br\/>/g, "\n"),
                },
                position: "left",
              };
              appendMsg(lastMsg);
            }
          } else if (response.type === "title") {
            onTitleUpdate(response.chat_id, response.text, response.created);
          }
        } catch (e) {
          // console.log("error", e);
        }
      });
    }
  }
  function renderMessageContent(msg) {
    const { content } = msg;
    if (content === undefined) {
      return null;
    }
    if (content.type === "calling") {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "10px",
            borderRadius: "5px",
            fontWeight: "bold",
            minWidth: "25%",
            backgroundColor: "#f7f8fa",
          }}
        >
          <div>
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <img
                style={{ display: content.isProcessed ? "block" : "none" }}
                src={require("../../Assets/success_chat.png")}
                alt=""
              />
              <img
                className="chat_loading"
                style={{ display: content.isProcessed ? "none" : "block" }}
                src={require("../../Assets/loading_chat.png")}
                alt=""
              />
              {content.isProcessed
                ? "操作执行完成"
                : `小卡AI助手正在执行操作:“${content.function_name}”`}
            </div>
          </div>
        </div>
      );
    } else if (content.type === "content") {
      return <MarkdownBubble content={content.text} />;
    } else if (content.type === "init") {
    }
    return null;
  }

  function loadMsg(chatId, messages) {
    resetList();
    setChatId(chatId);
    messages.forEach((item) => {
      if (item.role === "user") {
        appendMsg({
          _id: generateUUID(),
          type: "content",
          content: { type: "content", text: item.content },
          position: "right",
        });
      } else {
        if (item.function_call) {
          appendMsg({
            content: {
              function_name: item.function_call,
              type: "calling",
              isProcessed: true,
            },
            _id: generateUUID(),
          });
        } else {
          appendMsg({
            _id: generateUUID(),
            type: "content",
            content: { type: "content", text: item.content },
            position: "left",
          });
        }
      }
    });
  }

  useImperativeHandle(ref, () => ({
    loadMsg,
  }));

  function renderBeforeMessageList() {
    // message 的长度等于 0 的时候 返回 Empty 组件
    if (messages.length === 0) {
      return (
        <div
          className="full-height chat-ui_container"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Empty
            className="chatui_empty"
            children={
              <>
                <div className="empty_header">
                  <div className="title">
                    <img
                      className="logo"
                      src={require("../../Assets/chat/img8.png")}
                      alt=""
                    />
                    <div className="system-name"></div>
                  </div>
                  <div className="slogan">让小卡 AI 助手帮助你高效工作! </div>
                </div>

                <div className="quick-operstion_container">
                  {quickOperstionList.map((item, index) => {
                    return (
                      <>
                        <div
                          className="quick-operstion_card"
                          onClick={(e) => handleSend("text", item.question)}
                        >
                          <div className="quick-operstion_content">
                            <div className="quick-operstion_header">
                              <img src={item.icon} alt="" />
                              <div>{item.title}</div>
                            </div>
                            <div className="quick-operstion_body">
                              {item.question}
                            </div>
                          </div>
                          <div className="quick-operstion_sider__right">
                            {/* <SendOutlined
                              style={{ fontSize: 24 }}
                            ></SendOutlined> */}
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </>
            }
          ></Empty>
        </div>
      );
    }
  }
  return (
    <Chat
      navbar={{ title: "小卡AI助手" }}
      messages={messages}
      renderMessageContent={renderMessageContent}
      onSend={handleSend}
      loadMsg={loadMsg}
      renderBeforeMessageList={renderBeforeMessageList}
    />
  );
});

export default ChatComponents;
