import MainLayout from "../Layout/MainLayout";
import ChatPage from "../Pages/ChatPage/ChatPage";
import SchedeleComponent from "../Pages/SchedulePage/SchedulePage";
import ToDoComponent from "../Pages/ToDopage/ToDoComponent";
import ContactsComponant from "../Pages/ContactListPage/ContactListPage";
import DocumentPage from "../Pages/DocumentPage/DocumentPage";
import LoginPage from "../Pages/LoginPage/LoginPage";
import RegisterPage from "../Pages/RegisterPage/RegisterPage";
import { Navigate } from "react-router-dom";
import {
  CommentOutlined,
  ScheduleOutlined,
  ContactsOutlined,
  CheckSquareOutlined,
  FolderOutlined,
  NotificationOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import SettingPage from "../Pages/SettingPage/SettingPage";
import BeforeEach from "./BeforeEach";
import PrivateRoute from "./PrivateRoute";
import SchedulePage from "../Pages/ScheduleDemoPage/SchedulePage";

/**
 * @description 用于存放导航栏的需要权限的路由，同时 系统的导航菜单也是基于此路由表进行渲染的
 * @type {*}
 * */
const authRoutes = [
  {
    path: "/chat",
    element: <ChatPage></ChatPage>,
    title: "聊天",
    icon: <CommentOutlined></CommentOutlined>,
  },
// <<<<<<< HEAD
  // {
  //   path: "/schedele",
  //   element: <SchedeleComponent></SchedeleComponent>,
  //   title: "日程",
  //   icon: <ScheduleOutlined></ScheduleOutlined>,
  // },
  {
    path: "/schedele",
    element: <SchedulePage></SchedulePage>,
    title: "日程",
    icon: <ScheduleOutlined></ScheduleOutlined>,
  },
// =======
//   {
//     path: "/schedele",
//     element: <SchedeleComponent></SchedeleComponent>,
//     title: "日程",
//     icon: <ScheduleOutlined></ScheduleOutlined>,
//   },
  // {
  //   path: "/schedeleDemo",
  //   element: <SchedulePage></SchedulePage>,
  //   title: "日程demo",
  //   icon: <ScheduleOutlined></ScheduleOutlined>,
  // },
// >>>>>>> master
  {
    path: "/todo",
    element: <ToDoComponent></ToDoComponent>,
    icon: <CheckSquareOutlined></CheckSquareOutlined>,
    title: "待办",
  },
  {
    path: "/contacts",
    element: <ContactsComponant></ContactsComponant>,
    icon: <ContactsOutlined></ContactsOutlined>,
    title: "通讯录",
  },
  {
    path: "/document",
    element: <DocumentPage></DocumentPage>,
    icon: <FolderOutlined></FolderOutlined>,
    title: "文档",
  },
  {
    path: "/setting",
    element: <SettingPage></SettingPage>,
    icon: <SettingOutlined></SettingOutlined>,
    title: "设置",
  },
];

const routes = [
  {
    path: "/",
    element: <Navigate to={authRoutes[0].path}></Navigate>,
  },
  {
    path: "/",
    element: (
      <BeforeEach authRoutes={authRoutes}>
        <MainLayout></MainLayout>
      </BeforeEach>
    ),
    children: authRoutes,
  },
  {
    path: "/login",
    element: <LoginPage></LoginPage>,
  },
  {
    path: "/register",
    element: <RegisterPage></RegisterPage>,
  },
];
export { authRoutes };

export default routes;
